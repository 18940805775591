<div class="section resume">
  <div class="container">
    <div class="row">
      <div class="col s12 center title">
        <h3>Work Experience</h3>
      </div>
    </div>
  </div>
  <div class="container jobs">
    <!-- <div class="row" *ngFor="let j of jobs">
      <div class="col s1"></div>
      <div class="col s3 time">
        {{j.dates}}
      </div>
      <div class="col s7 job">
        <h3>
          {{j.title}}
        </h3>
        <p class="company">
          {{j.company}}
        </p>
        <p class="info">
          {{j.info}}
        </p>
      </div>
      <div class="col s1"></div>
    </div> -->
    <div class="row">
      <div class="col s1"></div>
      <div class="col s3 time">
        2017 - Present
      </div>
      <div class="col s7 job">
        <h3>Sr. Front End Web Developer</h3>
        <p class="company">
          Augmentir
        </p>
        <p class="info">
          Prototyping, Web design & development, iOS, Android, Realwear App Development
        </p>
        <ul>
          <li>
            Assessed all UX/UI designs for feasibility and effort
          </li>
          <li>
            Built VueJS component library for comprehensive set of common UI elements and internal framework
          </li>
          <li>
            Created advanced feature set pages including data driven AI/ML report pages and chat/video customer support service
          </li>
          <li>
            Designed and implemented front-end style guide and standards improving speed and consistency of dev team feature deployment
          </li>
        </ul>
      </div>
      <div class="col s1"></div>
    </div>
    <div class="row">
      <div class="col s1"></div>
      <div class="col s3 time">
        2016 - Present
      </div>
      <div class="col s7 job">
        <h3>Business Owner, Web Developer</h3>
        <p class="company">
          Capsule 42, LLC
        </p>
        <p class="info">
          Freelance web design & development
        </p>
      </div>
      <div class="col s1"></div>
    </div>
    <div class="row">
      <div class="col s1"></div>
      <div class="col s3 time">
        2014 - 2016
      </div>
      <div class="col s7 job">
        <h3>Dev Lead, Sr. Web Developer</h3>
        <p class="company">
          Vizualiiz, Inc.
        </p>
        <p class="info">
          Web design & development, iOS & Android app development
        </p>
        <ul>
          <li>
            Designed and built mobile app prototype for server asset management platform from concept to functional demo
          </li>
        </ul>
      </div>
      <div class="col s1"></div>
    </div>
    <div class="row">
      <div class="col s1"></div>
      <div class="col s3 time">
        2013 - 2014
      </div>
      <div class="col s7 job">
        <h3>Web Developer, Content Manager</h3>
        <p class="company">
          Verathon, Inc.
        </p>
        <p class="info">
          CMS architecture & development, content management, feature design & development
        </p>
        <ul>
          <li>
            Rebuilt customer facing templates removing content layout restrictions on the marketing team
          </li>
          <li>
            Revamped in-house content management system to bring support pages into FDA compliance and improve content update speed over 50%
          </li>
        </ul>
      </div>
      <div class="col s1"></div>
    </div>
    <div class="row">
      <div class="col s1"></div>
      <div class="col s3 time">
        2011 - 2013
      </div>
      <div class="col s7 job">
        <h3>Web Developer, Email Developer</h3>
        <p class="company">
          Classmates.com, Memory Lane
        </p>
        <p class="info">
          Weekly content development HTML Email development
        </p>
      </div>
      <div class="col s1"></div>
    </div>
    <div class="row">
      <div class="col s1"></div>
      <div class="col s3 time">
        2009 - 2011
      </div>
      <div class="col s7 job">
        <h3>Release Manager, Content Developer</h3>
        <p class="company">
          Microsoft – MSN.com
        </p>
        <p class="info">
          Weekly content release management HTML content development and conversion
        </p>
      </div>
      <div class="col s1"></div>
    </div>
    <div class="row">
      <div class="col s1"></div>
      <div class="col s3 time">
        2007 - 2009
      </div>
      <div class="col s7 job">
        <h3>Test Engineer, Web Developer</h3>
        <p class="company">
          Microsoft Hardware – Mouse and Keyboard
        </p>
        <p class="info">
          Software test planning, execution and reporting UI development on product web site
        </p>
      </div>
      <div class="col s1"></div>
    </div>
    <div class="row">
      <div class="col s1"></div>
      <div class="col s3 time">
        2001 - 2007
      </div>
      <div class="col s7 job">
        <h3>Test Engineer</h3>
        <p class="company">
          Microsoft Games Studio
        </p>
        <p class="info">
          Software test planning, execution and reporting
        </p>
      </div>
      <div class="col s1"></div>
    </div>
  </div>
</div>
