<div id="scroll-spacer"></div>
<div id="index-banner" class="parallax-container">
  <div class="parallax"><img src="./../../../assets/images/capsule-negative.gif" alt=""></div>
</div>
<div class="menu">
  <div class="bar left"></div>
  <div class="bar right"></div>
  <div class="center logo">Capsule 42 | Jason Baker</div>
</div>
<div class="section code">
  <div class="container">
    <div class="row intro">
      <span *ngFor="let i of codeHtml" class="{{i.class}}">{{i.value}}</span>
      <span class="cursor"></span>
    </div>
  </div>
</div>
<div class="top-fill"></div>
