<div class="section">
  <div class="container">
    <div class="row no-margin">
      <div class="col s12 center">
        <!-- <p> -->
        Copyright &copy; {{copyrightYear}} Jason Baker | Capsule 42, LLC
        <!-- </p> -->
      </div>
    </div>
  </div>
</div>
