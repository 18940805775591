<div class="social-spacer"></div>
<div class="section">
  <div class="container">
    <div class="row">
      <div class="col s12 center">
        <!-- platforms -->
        <div class="skill angular">
          <i class="fab fa-node-js"></i>
          <div class="label">
            Node.js
          </div>
        </div>
        <div class="skill angular">
          <i class="fab fa-angular"></i>
          <div class="label">
            Angular 2
          </div>
        </div>
        <div class="skill vue">
          <i class="fab fa-vuejs"></i>
          <div class="label">
            Vue.js
          </div>
        </div>
        <div class="skill wordpress">
          <i class="devicon-wordpress-plain"></i>
          <div class="label">
            WordPress
          </div>
        </div>
        <!-- UI -->
        <div class="skill html5">
          <i class="devicon-html5-plain"></i>
          <div class="label">
            HTML5
          </div>
        </div>
        <!-- functional languages -->
        <!-- <div class="skill express">
          <i class="devicon-express-original"></i>
          <div class="label">
            Express.js
          </div>
        </div> -->
        <div class="skill typescript">
          <i class="devicon-typescript-plain"></i>
          <div class="label">
            TypeScript
          </div>
        </div>
        <div class="skill javascript">
          <i class="devicon-javascript-plain"></i>
          <div class="label">
            JavaScript
          </div>
        </div>
        <div class="skill jquery">
          <i class="devicon-jquery-plain"></i>
          <div class="label">
            jQuery
          </div>
        </div>
        <div class="skill php">
          <i class="devicon-php-plain"></i>
          <div class="label">
            PHP
          </div>
        </div>
        <!-- styles -->
        <div class="skill css3">
          <i class="devicon-css3-plain"></i>
          <div class="label">
            CSS 3
          </div>
        </div>
        <div class="skill sass">
          <i class="devicon-sass-original"></i>
          <div class="label">
            Sass
          </div>
        </div>
        <div class="skill less">
          <i class="devicon-less-plain-wordmark"></i>
          <div class="label">
            Less
          </div>
        </div>

        <!-- other -->
        <div class="skill mysql">
          <i class="devicon-mysql-plain"></i>
          <div class="label">
            MySql
          </div>
        </div>
        <div class="skill git">
          <i class="devicon-git-plain"></i>
          <div class="label">
            GIT
          </div>
        </div>
        <div class="skill  bitbucket">
          <!-- <i class="fab fa-bitbucket"></i> -->
          <i class="devicon-bitbucket-plain"></i>
          <div class="label">
            Bitbucket
          </div>
        </div>
        <div class="skill atom">
          <i class="devicon-atom-original"></i>
          <div class="label">
            Atom IDE
          </div>
        </div>
        <div class="skill vscode">
          <i class="devicon-visualstudio-plain"></i>
          <div class="label">
            VSCode
          </div>
        </div>
        <div class="skill  jira">
          <i class="fab fa-jira"></i>
          <div class="label">
            Jira
          </div>
        </div>
        <div class="skill vscode">
          <i class="devicon-trello-plain"></i>
          <div class="label">
            Trello
          </div>
        </div>
        <div class="skill photoshop">
          <i class="devicon-photoshop-plain"></i>
          <div class="label">
            Photoshop
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
