<div class="section no-pad">
  <div class="container">
    <div class="row">
      <div class="col s12 grid">
        <a href="https://www.linkedin.com/in/capsule42/" target="_blank">
          <i class="fab fa-linkedin-in"></i>
        </a>
        <a href="https://github.com/jason-capsule42" target="_blank">
          <i class="fab fa-github"></i>
        </a>
        <a href="https://stackoverflow.com/users/8945603/capsule42" target="_blank">
          <i class="fab fa-stack-overflow"></i>
        </a>
        <a href="mailto:jason@capsule42.com" target="_blank">
          <i class="fas fa-envelope"></i>
        </a>
        <!-- <a href="https://www.facebook.com/oontsoonts" target="_blank"><span class="socicon-facebook"></span></a> -->
        <!-- <a href="https://www.mixcloud.com/weavermusic/" target="_blank"><span class="socicon-mixcloud"></span></a> -->
      </div>
    </div>
  </div>
</div>
